.site-header {
  #toggle {
    margin: 0;
    cursor: pointer;
    width: 100%;
    position: relative;
    z-index: 101;
    display: block !important;
    float: right;
    border-radius: 50%;
    text-align: center;
    top: 25%;
  }
  &__main {
    width: 100%;
    padding: 0px;
    .btn {
      max-width: 140px;
      min-width: 140px;
      svg {
      }
    }
  }
  &__logo{
    a {
      width:100%;
    }
  }
  &__home {
    font-size: 0;
    width: 100%;
    a {
      font-size: 25px;
      text-align: center;
      width: 100%;
      border-left: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      svg, img {
        display: block;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
      label {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 0.6rem;
        margin-top: 5px;
        text-transform: uppercase;
      }
    }
  }
  &__icons {
    font-size: 0;
    width: 100%;
    a {
      text-align: center;
      border-left: 1px solid #cccccc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      svg {
        display: block;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
      &:last-of-type {
        border-right: none !important;
      }
      label {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 0.6rem;
        margin-top: 5px;
        text-transform: uppercase;
      }
    }

  }
}

@media only screen and (min-width: 1101px) {
  .main-nav {
    display: none !important;
  }
}

@media only screen and (max-width: 1100px) {
  .main-nav {
    top: 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-nav ul {
    display: none !important;
  }
}
